<template>
    <section>
        <section class="info-container">
            <section class="info-item-wrap">
                <p class="info-title ">值班信息</p>
                <a-row class="p-t-14">
                    <a-col :span="8">
                        <a-row class="info-item">
                            <a-col class="key">
                                值班名称
                            </a-col>
                            <a-col class="val">
                                {{detailInfo.dutyName}}
                            </a-col>
                        </a-row>
                    </a-col>
                    <a-col :span="8">
                        <a-row class="info-item">
                            <a-col class="key">
                                值班地点
                            </a-col>
                            <a-col class="val">
                                {{detailInfo.dutyAdr}}
                            </a-col>
                        </a-row>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="8">
                        <a-row class="info-item">
                            <a-col class="key">
                                值班日期
                            </a-col>
                            <a-col class="val">
                                <span class="m-r-8">{{detailInfo.startDate}}</span>
                                <span class="m-r-10">{{detailInfo.startDateType == 1 ? '上午' : '下午'}}</span>
                                <span class="m-r-10">-</span>
                                <span class="m-r-8">{{detailInfo.endDate}}</span>
                                <span>{{detailInfo.endDateType == 1 ? '上午' : '下午'}}</span>
                            </a-col>
                        </a-row>
                    </a-col>
                    <a-col :span="8">
                        <a-row class="info-item">
                            <a-col class="key">
                                值班时长
                            </a-col>
                            <a-col class="val">
                                <span class="m-r-8"></span>{{detailInfo.duration || 0}} 天
                            </a-col>
                        </a-row>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="16">
                        <a-row class="info-item">
                            <a-col class="key">
                                值班说明
                            </a-col>
                            <a-col class="val">
                                {{detailInfo.remarks}}
                            </a-col>
                        </a-row>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="8">
                        <a-row class="info-item">
                            <a-col class="key">
                                值班人数
                            </a-col>
                            <a-col class="val">
                                {{detailInfo.personNum}}
                            </a-col>
                        </a-row>
                    </a-col>
                    <a-col :span="16">
                        <a-row class="info-item">
                            <a-col class="key">
                                值班人员
                            </a-col>
                            <a-col class="val">
                                {{detailInfo.dutyPersons}}
                            </a-col>
                        </a-row>
                    </a-col>
                </a-row>
            </section>

            <section class="info-item-wrap m-t-18">
                <p class="info-title">考勤信息</p>
                <section class="list-table-container m-t-18">
                    <a-table size="small" :columns="columns" :data-source="listMap" :pagination="{hideOnSinglePage:true}">
                        <a-row slot="signInPhoto" slot-scope="text" v-if="text">
                            <previewImage :data="text" :size="40" />
                        </a-row>
                        <span slot="signInState" slot-scope="text">{{text === 0 ?'正常' : text === 1 ? '迟到' : text === 2 ? '缺卡' : text === 3 ? '早退':  text === 4 ? '请假' : ''}}</span>
                        <span slot="signOutState" slot-scope="text">{{text === 0 ?'正常' : text === 1 ? '迟到' : text === 2 ? '缺卡' : text === 3 ? '早退': text === 4 ? '请假' : ''}}</span>
                        <span slot="deviceType" slot-scope="text">{{text === 1 ?'门禁设备' : '考勤设备'}}</span>
                        <a-row slot="signOutPhoto" slot-scope="text" v-if="text">
                            <previewImage :data="text" :size="40" />
                        </a-row>
                    </a-table>
                </section>
            </section>
        </section>
    </section>
</template>

<script>
const columns = [
    {title:'序号',dataIndex:'key',align:'center',key:'key'},
    {title:'姓名',dataIndex:'personnelName',align:'center',key:'personnelName'},
    {title:'职务',dataIndex:'jobName',align:'center',key:'jobName'},
    {title:'设备类型',dataIndex:'deviceType',align:'center',key:'deviceType',scopedSlots:{customRender: "deviceType"}},
    {title:'设备名称',dataIndex:'deviceName',align:'center',key:'deviceName'},
    {title:'考勤日期',dataIndex:'attendanceDate',align:'center',key:'attendanceDate'},
    {title:'上班签到时间',dataIndex:'signInTime',align:'center',key:'signInTime'},
    {title:'签到状态',dataIndex:'signInState',align:'center',key:'signInState',scopedSlots:{customRender: "signInState"}},
    {title:'签到照片',dataIndex:'signInPhoto',align:'center',key:'signInPhoto',scopedSlots:{customRender: "signInPhoto"}},
    {title:'下班签到时间',dataIndex:'signOutTime',align:'center',key:'signOutTime'},
    {title:'签到状态',dataIndex:'signOutState',align:'center',key:'signOutState',scopedSlots:{customRender: "signOutState"}},
    {title:'签到照片',dataIndex:'signOutPhoto',align:'center',key:'signOutPhoto',scopedSlots:{customRender: "signOutPhoto"}},
]
import previewImage from '@/components/previewImage'
export default {
    data(){
        return{
            columns,
            detailInfo:{},
            listMap:[]
        }
    },
    components:{previewImage},
    computed:{
        did(){
            return this.$route.query.id
        }
    },
    created(){
        this.queryDutyApplyDetails()
    },
    methods:{
        queryDutyApplyDetails(){
            this.$api.queryDutyApplyDetails(this.did).then(res =>{
                if(res.code === 200){
                    this.detailInfo = (res.data && res.data.dutyApply) || {}
                    let pageList = (res.data && res.data.pageList) || {}
                    this.listMap = pageList.records || []
                    this.listMap.forEach((item,ind) =>{
                        item.key = ind + 1
                    })
                }
            })
        }
    }
}
</script>