<template>
    <a @click="handlePreview">
        <a-avatar  v-if="imgUrl" class="upload-pic-preview" shape="square" :size="size" :src="imgUrl.includes('.pdf') ? pdfIcon : imgUrl " />
        <!-- 图片预览 -->
        <a-modal class="modal-container" centered title="图片预览" v-model="previewVisible" :footer="null" :width="previewImage.includes('.pdf') ? 1080 :480">
            <iframe v-if="previewImage.includes('.pdf')" :src="previewImage" frameborder="0" scrolling="no" width="100%" height="600px"></iframe>
            <a-row class="preview-img-wrap" v-else>
                <img style="width:100%" :src="previewImage" />
            </a-row>
        </a-modal>
    </a>
    
</template>

<script>
    export default {
        data() {
            return {
                previewVisible: false,
                previewImage:'',
                pdfIcon:require('../assets/image/icon/file/pdf.png'),
                wordIcon:require('../assets/image/icon/file/word.jpg'),
                excelIcon:require('../assets/image/icon/file/excel.jpg'),
            }
        },
        props: ['data','size'],
        computed: {
            picServer() {
                return this.$store.state.picServer
            },
            imgUrl() {
                return this.data.includes('http') ? this.data : this.picServer+this.data
            }
        },
        created(){

        },
        methods: {
            handlePreview() {
                this.previewVisible = true
                this.previewImage = this.imgUrl.includes('http') ? this.imgUrl : this.picServer+this.imgUrl
            }
        }
    }
</script>